import {Competition, CompetitionStatus} from '@shared/models/competition';
import React, {useMemo} from 'react';
import {ITextProps} from 'native-base';
import LimeLabel from '@ui/components/label/LimeLabel';
import {formatDistance} from 'date-fns';
import {getDatabaseDate} from '@shared/models/firestore-timestamp';
import {useTranslation} from 'react-i18next';

const LabelStatuses = [CompetitionStatus.Ongoing, CompetitionStatus.Upcoming, CompetitionStatus.Registration, CompetitionStatus.Finished, CompetitionStatus.Cancelled];

interface Props extends ITextProps {
    competition: Pick<Competition, 'status' | 'startsAt' | 'challengeType'>;
    userJoined?: boolean;
    longLabel?: boolean;
}

const CompetitionStatusLabel = ({competition, userJoined, longLabel = true, ...restProps}: Props) => {
    const {status, startsAt} = competition;

    const {t} = useTranslation();
    const showLabel = LabelStatuses.includes(status);
    const text = useMemo(() => {
        if(status === CompetitionStatus.Finished) {
            return t('competition.status.finished');
        }

        if(status === CompetitionStatus.Cancelled) {
            return t('competition.status.cancelled');
        }

        if(userJoined) {
            const prefix = t('competition.status.joined');
            const suffix = formatDistance(new Date(getDatabaseDate(startsAt)), new Date());
            if(status === CompetitionStatus.Registration || status === CompetitionStatus.Upcoming){
                return longLabel ? `${prefix} · ${t('competition.status.startsIn', {time: suffix})}` : prefix;
            }
            return prefix;
        }

        if(status === CompetitionStatus.Upcoming || status === CompetitionStatus.Registration) {
            return `${t('competition.status.startsIn', {time: formatDistance(new Date(getDatabaseDate(startsAt)), new Date())})}`;
        }

        return `${t(`competition.status.${status.toLowerCase()}`)}`;
    }, [status, userJoined]);

    const bg = useMemo(() => {
        if(status === CompetitionStatus.Cancelled) {
            return 'red.500';
        }

        if(status === CompetitionStatus.Finished) {
            return 'blacks.600';
        }

        if(status === CompetitionStatus.Upcoming || status === CompetitionStatus.Registration) {
            return 'white';
        }

        return 'lime.default';
    }, [status]);

    const color = useMemo(() => {
        if(status === CompetitionStatus.Cancelled) {
            return 'white';
        }

        if(status === CompetitionStatus.Finished) {
            return 'white';
        }

        if(status === CompetitionStatus.Upcoming || status === CompetitionStatus.Registration) {
            return 'black';
        }

        return 'black';
    }, [status]);

    if(!showLabel && !userJoined) {
        return null;
    }

    return (
        <LimeLabel
            textTransform="uppercase"
            bg={bg}
            color={color}
            _wrapper={{bg, rounded:'md'}}
            {...restProps}
        >
            {text}
        </LimeLabel>
    );
};

export default CompetitionStatusLabel;
