import {Competition, CompetitionUiPreviewData} from '@shared/models/competition';
import {DivotsImageLabel} from '@ui/features/wallet/DivotsLabel';
import React from 'react';
import {Tag} from '@shared/models/tag';
import {TransactionActions} from '@shared/models/wallet';
import {get} from 'lodash';
import {getTransactionActionValue} from '@shared/helpers/wallet-fn';

interface LabelProps {
    competition: Competition | CompetitionUiPreviewData;
}
export const CourseCardDivotsLabel = (props: LabelProps) => {
    const {competition} = props;

    const isTip = get(competition, 'info.tags', [] as Tag[]).some((tag) => tag.text.en.toLowerCase() === 'tip');

    return (
        <DivotsImageLabel divots={`+${getTransactionActionValue(isTip ? TransactionActions.VIDEO_VIEWED : TransactionActions.CHALLENGE_FINISHED_MULTIPLE)}`} />
    );
};
