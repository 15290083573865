import {Box, IBoxProps} from 'native-base';
import {Competition} from '@shared/models/competition';
import {Dimensions} from 'react-native';
import {PolygonBackground} from '@ui/components/PolygonBackground';
import React from 'react';
import {SolitoImageProps} from 'solito/build/image/image.types';
import WAMPImage from '@ui/components/image/WAMPImage';
import {WEB_LG_MAX_WIDTH} from '@ui/helpers/constants';

const {width: screenWidth} = Dimensions.get('window');

type CompetitionHeaderType = 'banner' | 'header' | 'logo' | 'featured';

export const getCompetitionHeaderImage = (competition: Pick<Competition, 'visuals'>, isFullWidth: boolean, type: CompetitionHeaderType) => {
    if(type === 'featured') {
        return competition.visuals?.featured || competition.visuals?.header || competition.visuals?.banner;
    }

    if(type === 'logo') {
        return competition.visuals?.logo;
    }

    if((type === 'banner' && competition.visuals.banner) || competition.visuals.header == undefined) {
        return competition.visuals?.banner;
    }

    return competition.visuals!.header;
};

interface CompetitionHeaderProps {
    competition: Pick<Competition, 'name' | 'visuals'>;
    isFullWidth?: boolean;
    _container?: IBoxProps;
    type?: CompetitionHeaderType;
    contentFit?: SolitoImageProps['contentFit'];
    height?: number;
}

export const CompetitionHeader = (props: CompetitionHeaderProps) => {
    const {competition, height, isFullWidth = false, _container, type = 'header', contentFit = 'cover'} = props;

    const imageSrc = getCompetitionHeaderImage(competition, isFullWidth, type);

    const minH = {
        base: (screenWidth - 32) / 2,
        lg: WEB_LG_MAX_WIDTH / 2,
    };

    return (
        <Box
            w="full"
            overflow='hidden'
            minH={height ?? minH}
            flex={1}
            {..._container}
        >
            {imageSrc ?
                <WAMPImage
                    contentFit={contentFit}
                    src={imageSrc.xl}
                    fill
                    alt={competition.name.en}
                    image={imageSrc}
                /> :
                <PolygonBackground flex={1}/>
            }
        </Box>
    );
};
