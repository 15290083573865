import {Box, Column, ITextProps, Text} from 'native-base';
import {Competition, CompetitionUiPreviewData} from '@shared/models/competition';
import React, {useCallback} from 'react';
import CompetitionDates from './CompetitionDates';
import {CompetitionHeader} from '@ui/features/competition/components/CompetitionHeader';
import CompetitionStatusLabel from './CompetitionStatusLabel';
import {CourseCardDivotsLabel} from '@ui/components/label/CourseCardDivotsLabel';
import {EntityUiData} from '@shared/models/entity';
import LabelRow from '@ui/components/label/LabelRow';
import NavigationLink from '@ui/components/buttons/NavigationLink';
import ParticipantAvatar from '@ui/components/participant/ParticipantAvatar';
import {Tag} from '@shared/models/tag';
import {get} from 'lodash';
import {getScreen} from '@ui/provider/navigation/screens';
import {isChallengeCourse} from '@shared/helpers/competition-fn';
import {useCompetition} from '@ui/provider/competition/CompetitionProvider';
import {useEntity} from '@ui/provider/entity/EntityProvider';
import {useTranslation} from 'react-i18next';

interface CompetitionCardProps {
    competition: Competition | CompetitionUiPreviewData;
    userJoined?: boolean;
    imageHeight?: number;
    _title?: ITextProps;
}

const CompetitionCard = (props: CompetitionCardProps) => {
    const {competition, userJoined, imageHeight, _title} = props;
    const {t} = useTranslation();
    const {setSelectedCompetition, setSelectedCompetitionId} = useCompetition();
    const {setSelectedEntity, setSelectedEntityId} = useEntity();

    const entities = Object.values(competition.community?.entities ?? {});

    const onPress = useCallback(() => {
        setSelectedCompetitionId(competition.id);
        setSelectedCompetition(competition);
    }, [competition]);

    const onPressCreator = useCallback((entity: EntityUiData) => {
        if (entity) {
            setSelectedEntityId(entity.id);
            setSelectedEntity(entity);
        }
    }, []);

    const isCourse = isChallengeCourse(competition);

    const path = {
        pathname: isCourse ? getScreen('challengeChapter') : getScreen('competition'),
        query: {
            id: competition.id,
            groupId: competition.currentGroupId,
            entry: 'list',
        },
    };

    const labels = (get(competition, 'info.tags', []) as Tag[]).map((tag: Tag) => tag.text.en);
    const description = get(get(competition, 'info.description', {}), 'en', '');

    return (
        <NavigationLink path={path} onPress={onPress} isCard>
            <Column
                w='full'
                bg='white'
                rounded='lg'
                overflow="hidden"
            >
                <CompetitionHeader competition={competition as Competition} height={imageHeight} />

                {isCourse &&
                <Box position="absolute" right='4' top='4'>
                    <CourseCardDivotsLabel competition={competition} />
                </Box>
                }

                {!isCourse &&
                        <Box
                            alignItems='flex-start'
                            position="absolute" left='4' top='4'
                        >
                            <CompetitionStatusLabel competition={competition as Competition} userJoined={userJoined} />
                        </Box>
                }

                {labels.length > 0 &&
                <Box p='4' pb={0}>
                    <LabelRow labels={labels} _label={{bg: 'blacks.100'}} mb={0}/>
                </Box>
                }

                <Column
                    p={4}
                    space='2'
                >
                    {!isCourse && <CompetitionDates competition={competition as Competition} color='darkGreen'/>}

                    <Text variant='mediumSemiBold' ellipsizeMode='tail' {..._title}>{competition.name.en}</Text>

                    {description.length > 0 && <Text variant='bodySm' color='blacks.600' numberOfLines={3} ellipsizeMode='tail'>{description}</Text>}

                    {entities.length > 0 &&
                        <Column mt='2' space={2}>
                            {entities.map(entity => (
                                <NavigationLink key={entity.id} path={{pathname: getScreen('creator'), query: {id: entity.id}}} onPress={() => onPressCreator(entity)}>
                                    <ParticipantAvatar
                                        participant={entity}
                                        avatarTextShifted
                                        _avatar={{size: 'xs'}}
                                        space='2'
                                        rounded="sm"
                                        direction="row"
                                        flex={1}
                                        _text={{variant:'body', fontWeight: 500}}
                                        showInitials={false}
                                        leftText={<Text variant='body'>{t('entity.by')}</Text>}
                                    />
                                </NavigationLink>
                            ))}
                        </Column>
                    }
                </Column>
            </Column>
        </NavigationLink>
    );
};

export default React.memo(CompetitionCard);
