import {ITextProps, Text} from 'native-base';
import React, {useMemo} from 'react';
import {format, getDay, getMonth, getYear} from 'date-fns';
import {Competition} from '@shared/models/competition';
import {getDatabaseDate} from '@shared/models/firestore-timestamp';

const DAY_FORMAT = 'd MMM';
const DAY_YEAR_FORMAT = 'd MMM yyyy';

export const formatStartAndEndDate = (competition: Competition) => {
    const startDate = getDatabaseDate(competition.startsAt);
    const endDate = getDatabaseDate(competition.endsAt);

    if(getDay(startDate) === getDay(endDate) && getMonth(startDate) === getMonth(endDate)) {
        return `${format(startDate, DAY_YEAR_FORMAT)}`;
    }

    const equalYear = getYear(startDate) === getYear(endDate);

    return `${format(startDate, equalYear ? DAY_FORMAT : DAY_YEAR_FORMAT)} - ${format(endDate, DAY_YEAR_FORMAT)}`;
};

interface Props extends ITextProps {
    competition: Competition;
}

const CompetitionDates = ({competition, ...restProps}: Props) => {
    const formattedDate = useMemo(() => formatStartAndEndDate(competition), [competition]);

    return (
        <Text variant='bodySm' fontWeight={600} textTransform='uppercase' {...restProps}>{formattedDate}</Text>
    );
};

export default CompetitionDates;
