import {Box, IBoxProps, ITextProps, Text} from 'native-base';
import React from 'react';

interface Props extends ITextProps {
    _wrapper?: IBoxProps;
}

const Label = (props:Props) => {
    const {_wrapper, ...rest} = props;

    return (
        <Box
            rounded='lg'
            bg='white'
            px='2'
            py='1'
            {..._wrapper}
        >
            <Text variant='bodySm' color='black' fontWeight={500} {...rest} />
        </Box>
    );
};

export default Label;
