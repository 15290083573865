import {IBoxProps, Row} from 'native-base';
import {IHStackProps} from 'native-base/lib/typescript/components/primitives/Stack/HStack';
import Label from './Label';
import React from 'react';

interface LabelRowProps extends IHStackProps{
    labels: string[];
    _label?: IBoxProps;
}

const LabelRow = (props: LabelRowProps) => {
    const {labels, _label, ...rest} = props;
    return (
        <Row space='2' mb='8' flexWrap="wrap" {...rest}>
            {labels.map((label, index) => (
                <Label key={index} _wrapper={_label}>{label}</Label>
            ))}
        </Row>
    );
};

export default LabelRow;
