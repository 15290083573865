import {Box, IBoxProps, StatusBar, useTheme} from 'native-base';
import {StyleSheet, useWindowDimensions} from 'react-native';
import Svg, {Polygon, SvgProps} from 'react-native-svg';
import React from 'react';
import {range} from 'lodash';
import useColors from '@ui/hooks/native-base/use-colors';

interface PolygonProps extends SvgProps{
    fullWidth?: number;
    fullHeight?: number;
    backgroundColor?: string;
}

export const PolygonSvg = (props: PolygonProps) => {
    const {width, height} = useWindowDimensions();
    const {fullWidth = width, fullHeight = height, backgroundColor} = props;
    const theme = useTheme();

    const PolygonHeight = 100;
    const PolygonWidth = 180;
    const PolygonStrokeWidth = 60;

    const rangeX = fullWidth / (PolygonWidth / 3);
    const rangeY = fullHeight / PolygonHeight;

    return (
        <Svg
            width={fullWidth}
            height={fullHeight}
            style={[{backgroundColor: backgroundColor ?? theme.colors.darkGreen}, StyleSheet.absoluteFillObject]}
            {...props}
        >
            {range(rangeY).flatMap((y) => {
                return range(rangeX).map((x) => (
                    <Polygon
                        points={`${x * 2 * PolygonStrokeWidth - PolygonWidth / 2 - y * PolygonStrokeWidth / 2},${y * PolygonHeight} 
                            
                            ${PolygonStrokeWidth + x * 2 * PolygonStrokeWidth - PolygonWidth / 2 - y * PolygonStrokeWidth / 2},${y * PolygonHeight} 
                    
                            ${PolygonWidth + x * 2 * PolygonStrokeWidth - PolygonWidth / 2 - y * PolygonStrokeWidth / 2},${y * PolygonHeight + PolygonHeight}
                    
                            ${(PolygonWidth - PolygonStrokeWidth) + x * 2 * PolygonStrokeWidth - PolygonWidth / 2 - y * PolygonStrokeWidth / 2},${y * PolygonHeight + PolygonHeight}
                    `}
                        key={`${x}-${y}`}
                        fill={props.fill ?? '#00331F'}
                    />
                ));
            },
            )}

        </Svg>
    );
};

interface Props extends IBoxProps{
    fullScreen?: boolean;
    name?: string;
}

export const PolygonBackground = (props: Props) => {
    const {fullScreen = false, name} = props;
    const colors = useColors();

    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(200);

    // const fill = name ? randomColorFromString(name) : undefined;
    // const background = name ? get(colors, `${fill}.600`) : colors.darkGreen;
    const fill = undefined;
    const background = colors.darkGreen;

    return (
        <Box
            {...props}
            w='full'
            h='full' onLayout={(event) => {
                const {width: w, height: h} = event.nativeEvent.layout;
                setWidth(w);
                setHeight(h);
            }}
        >
            {fullScreen && <StatusBar barStyle="light-content" backgroundColor={background} animated/>}

            <PolygonSvg fullWidth={width} fullHeight={height} backgroundColor={background} fill={fill} />

            {props.children}
        </Box>
    );
};
