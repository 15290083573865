import {TransactionActions} from '@shared/models/wallet';
import {get} from 'lodash';

const actionValue = {
    [TransactionActions.ACCOUNT_CREATION]: 100,
    [TransactionActions.ACCOUNT_COMPLETION]: 200,
    [TransactionActions.TEAM_CREATION]: 50,
    [TransactionActions.CHALLENGE_FINISHED]: 50,
    [TransactionActions.CHALLENGE_FINISHED_MULTIPLE]: 50,
    [TransactionActions.CHALLENGE_JOINED]: 150,
    [TransactionActions.COMMUNITY_JOINED]: 100,
    [TransactionActions.USER_INVITE]: 100,
    [TransactionActions.USER_INVITE_MULTIPLE]: 25,
    [TransactionActions.FIXTURE_CREATION]: 50,
    [TransactionActions.VIDEO_VIEWED]: 10,
    [TransactionActions.LOGIN_DAILY]: 10,
    [TransactionActions.LOGIN_FULL_WEEK]: 70,
    [TransactionActions.LOGIN_MONTHLY]: 100,
    [TransactionActions.LAST_LOGIN_MONTH_AGO]: 200,
    [TransactionActions.PLAY_DRILL]: 50,
};

const getTransactionActionValue = (action: TransactionActions) => {
    return get(actionValue, action, 0);
};

export {getTransactionActionValue};
